import React from "react";
import { Card } from "antd";
import { styled } from "linaria/react";
import { theme } from "@project/shared/src/theme";
import News from "../../../assets/icons/news.svg";
import Event from "../../../assets/icons/event.svg";
import Article from "../../../assets/icons/article.svg";
import { useTranslation } from "react-i18next";

import Link from "next/link";

const StyledCard = styled(Card)`
  padding: 0px;
  background-repeat: no-repeat;
  border-radius: 10px;
  height: 260px;
  background-size: cover;
  background-position: center;
  cursor: pointer;
  & .ant-card-body {
    width: 100%;
  }
`;
const InfoBox = styled.div`
  & strong {
    font-weight: bold;
    font-size: 24px;
    color: ${theme.white};
  }
  & p {
    font-size: 14px;
    color: ${theme.white};
    margin-top: 12px;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  @media (max-width: 475px) {
    & strong {
      font-size: 18px;
    }

    & p {
      font-size: 10px;
    }
  }
`;

const PointInformation = styled.div`
  position: absolute;
  bottom: 30px;
  width: 95%;
  margin-top: 20px;
  display: grid;
  grid-gap: 10px;
  grid-template-columns: repeat(auto-fill, minmax(100px, 1fr));

  & .info {
    display: flex;
    align-items: center;
  }
  & .basicInfo {
    color: ${theme.white};
    display: flex;
    flex-direction: column;
    margin-left: 18px;
  }

  @media (max-width: 475px) {
    margin-top: 16px;
    & span {
      font-size: 10px;
      line-height: 14px;
    }
  }
`;

const CityCardWrapper = styled.div`
  flex-basis: 33.333333%;
  padding: 10px;

  @media (max-width: 750px) {
    flex-basis: 100%;
  }

  @media (min-width: 750px) and (max-width: 1000px) {
    flex-basis: 50%;
  }
`;

interface CityCardProps {
  image?: string;
  population?: string;
  slug?: string;
  news?: string;
  support?: number;
  event?: number;
  article?: number;
  university?: number;
  placeName?: string;
  description?: string;
  count?: number;
}
const CityCard = ({
  image,
  placeName,
  slug,
  news,
  event,
  article,
}: CityCardProps) => {
  const { t } = useTranslation();

  return (
    <CityCardWrapper>
      <Link href={`/city/${slug}`}>
        <a>
          <StyledCard
            style={{
              backgroundImage: `url(${image})`,
            }}
            onClick={
              slug === ""
                ? null
                : () => {
                    localStorage.setItem("articalTab", "0");
                  }
            }
          >
            <InfoBox>
              <strong>{t(placeName)}</strong>
              <PointInformation>
                {Number(news) > 0 && (
                  <div className="info">
                    <News />
                    <div className="basicInfo">
                      <span>{news}</span>
                      <span>{t("News")}</span>
                    </div>
                  </div>
                )}
                {Number(event) > 0 && (
                  <div className="info">
                    <Event />
                    <div className="basicInfo">
                      <span>{event}</span>
                      <span>{t("Events")}</span>
                    </div>
                  </div>
                )}
                {Number(article) > 0 && (
                  <div className="info">
                    <Article />
                    <div className="basicInfo">
                      <span>{article}</span>
                      <span>{t("Article")}</span>
                    </div>
                  </div>
                )}
              </PointInformation>
            </InfoBox>
          </StyledCard>
        </a>
      </Link>
    </CityCardWrapper>
  );
};
export { CityCard };
