import React, { useState } from "react";
import { styled } from "linaria/react";
import { theme } from "@project/shared/src/theme";
import { useQuery } from "react-query";
import { GetFeaturedArticle } from "../../../service/article";
import RightArrow from "../../../assets/icons/right.svg";
import LeftArrow from "../../../assets/icons/left.svg";
import Carousel from "react-multi-carousel";
import { NoticeCard } from "../../molecules/NoticeCard";
const StyledDiv = styled.div`
  position: relative;

  & .title {
    position: absolute;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 40px;
    color: ${theme.textColor};
    left: 8.4%;
  }
  & .count {
    position: absolute;
    z-index: 100;
    height: 56px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #c6c6c6;
    left: 345px;
    bottom: 55px;
    & span {
      color: ${theme.buttonRed};
    }
    @media (max-width: 975px) {
      bottom: 33px;
    }
    @media (max-width: 925px) {
      left: 300px;
    }
    @media (max-width: 768px) {
      bottom: unset;
      top: 290px;
      right: 17px;
      left: unset;
    }
  }
  & .react-multi-carousel-track {
    opacity: 1 !important;

    transition-duration: 0s !important;
    transition-property: opacity !important;
  }
  & .react-multi-carousel-item--active {
    animation: fade 1s;
  }
  @keyframes fade {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;
const StyledCustomRightButton = styled.button`
  position: absolute;
  left: 447px;
  bottom: 56px;
  background: ${theme.buttonRed};
  border: none;
  width: 56px;
  height: 56px;
  cursor: pointer;
  border-left: 1px solid ${theme.white};
  &:hover {
    background: #cb3341;
  }
  @media (max-width: 975px) {
    bottom: 34px;
  }
  @media (max-width: 925px) {
    left: 394px;
  }
  @media (max-width: 768px) {
    bottom: unset;
    top: 241px;
    right: 0px;
    left: unset;
  }
  &:disabled {
    background: #cbcdcb;
  }
`;
const StyledCustomLeftButton = styled.button`
  position: absolute;
  left: 391px;
  bottom: 56px;
  background: ${theme.buttonRed};

  border: none;
  width: 56px;
  height: 56px;
  margin-right: 0.5px;
  cursor: pointer;
  @media (max-width: 975px) {
    bottom: 34px;
  }
  @media (max-width: 925px) {
    left: 338px;
  }
  @media (max-width: 768px) {
    bottom: unset;
    top: 241px;
    right: 56px;
    left: unset;
  }
  & svg > path {
    fill: ${theme.white};
  }
  &:disabled {
    background: #cbcdcb;
  }
`;
const Wrapper = styled.div`
  top: 0px;
  margin: 0 auto;
  max-height: 645px;
`;

const MainContainer = styled.div`
  max-width: 1440px;
  margin: 64px auto;
  @media (max-width: 1550px) {
    padding: 0px 5% 0px 5%;
  }
  @media (max-width: 1440px) {
    padding: 0px 9.02% 0px 8.33%;
  }
  @media (max-width: 1245px) {
    padding: 0px 5%;
  }
  @media (max-width: 1150px) {
    padding: 0px 3%;
  }
  @media (max-width: 768px) {
    padding: 16px 16px 0px 16px;
    margin: 0px;
  }
`;
const StyledButtonGroup = styled.div`
  position: absolute;
  bottom: 0px;
  /* right: 8.4%; */
  @media (max-width: 768px) {
    top: 0px;
    right: 0px;
  }
`;

interface NoticeProps {
  city?: any;
  initialData: any;
}

export const NoticeCarousel = ({ city, initialData }: NoticeProps) => {
  const [currentSlideNum, setCurrentSlide] = useState(1);
  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      currentSlide: 1,
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const { data } = useQuery<any, any>(
    ["getNoticeList", city],
    GetFeaturedArticle,
    {
      initialData: initialData,
      refetchOnWindowFocus: false,
    }
  );

  const ButtonGroup = ({ ...rest }) => {
    const {
      carouselState: { currentSlide },
      previous,
      next,
    } = rest;
    const initialCount = 1;
    const count = initialCount + currentSlide;
    setCurrentSlide(count);
    return (
      <>
        <StyledButtonGroup className="carousel-button-group">
          <StyledCustomLeftButton
            disabled={currentSlideNum == 1 ? true : false}
            color="gray"
            onClick={() => previous()}
          >
            <LeftArrow />
          </StyledCustomLeftButton>
          <StyledCustomRightButton
            disabled={currentSlideNum == data?.data?.length ? true : false}
            onClick={() => next()}
          >
            <RightArrow />
          </StyledCustomRightButton>
        </StyledButtonGroup>
      </>
    );
  };

  if (data?.data.length === 0) {
    return <></>;
  }

  return (
    <MainContainer>
      <Wrapper>
        <StyledDiv>
          {data && (
            <strong className="count">
              <span>{currentSlideNum}/</span>
              {data?.data?.length}
            </strong>
          )}
          {data && (
            <Carousel
              customButtonGroup={<ButtonGroup />}
              renderButtonGroupOutside
              slidesToSlide={1}
              partialVisible={true}
              responsive={responsive}
            >
              {data?.data?.map((item, index) => (
                <NoticeCard
                  slug={item.slug}
                  image={item.coverImage}
                  title={item?.title}
                  description={item?.overview}
                  key={`notice-${index}`}
                />
              ))}
            </Carousel>
          )}
        </StyledDiv>
      </Wrapper>
    </MainContainer>
  );
};
