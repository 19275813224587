import { API, i18n } from "@project/shared";

export const GetCityInformation = async () => {
  return API.get("users/city-information", {
    params: {
      language: i18n.language?.startsWith("en") ? "en" : "ja",
    },
  });
};

export const CityList = () => {
  const data = [
    {
      id: 1,
      placeName: "Osaka (Osaka Prefecture and Osaka City)",
      slug: "osaka",
      image: "/osaka.jpg",
      population: "1.1 million",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis mauris nunc diam, nisl arcu sit lobortis quis. Purus orci, vulputate idLorem ipsum dolor sit amet, consectetur adipiscing elit.",
      support: 52,
      university: "30+",
    },
    {
      id: 2,
      placeName: "Sakai",
      slug: "sakai",
      image: "/sakai.jpg",
      population: "1.1 million",
      description:
        "Kyoto is a city of innovation, fusing tradition, culture and technology into hardworking harmony.",
      support: 52,
      university: "30+",
    },
    {
      id: 4,
      placeName: "Kyoto (Kyoto Prefecture and Kyoto City)",
      slug: "kyoto",
      image: "/kyoto.jpg",
      population: "1.1 million",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis mauris nunc diam, nisl arcu sit lobortis quis. Purus orci, vulputate idLorem ipsum dolor sit amet, consectetur adipiscing elit.",
      support: 52,
      university: "30+",
    },
    {
      id: 3,
      placeName: "HyogoKobe（Hyogo Prefecture and Kobe City）",
      slug: "hyogo_kobe",
      image: "/hyogo_kobe.jpg",
      population: "1.1 million",
      description:
        "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Quis mauris nunc diam, nisl arcu sit lobortis quis. Purus orci, vulputate idLorem ipsum dolor sit amet, consectetur adipiscing elit.",
      support: 52,
      university: "30+",
    },
    {
      id: 7,
      placeName: "Tokushima",
      slug: "tokushima",
      image: "/tokushima.jpg",
      population: "1.1 million",
      description:
        "Kyoto is a city of innovation, fusing tradition, culture and technology into hardworking harmony.",
      support: 52,
      university: "30+",
    },
    {
      id: 5,
      placeName: "Shiga",
      slug: "shiga",
      image: "/shiga.jpg",
      population: "1.1 million",
      description:
        "Kyoto is a city of innovation, fusing tradition, culture and technology into hardworking harmony.",
      support: 52,
      university: "30+",
    },
    {
      id: 6,
      placeName: "Wakayama",
      slug: "wakayama",
      image: "/wakayama.jpg",
      population: "1.1 million",
      description:
        "Kyoto is a city of innovation, fusing tradition, culture and technology into hardworking harmony.",
      support: 52,
      university: "30+",
    },
    {
      id: 8,
      placeName: "Tottori",
      slug: "tottori",
      image: "/tottori.jpg",
      population: "1.1 million",
      description:
        "Kyoto is a city of innovation, fusing tradition, culture and technology into hardworking harmony.",
      support: 52,
      university: "30+",
    },
  ];
  return data;
};
