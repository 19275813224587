import { Card } from "antd";
import React, { useEffect, useState } from "react";
import { styled } from "linaria/react";
import CardArrow from "../../../assets/cardarrow.svg";
import { theme } from "@project/shared/src/theme";
import { useTranslation } from "react-i18next";
import { Button, i18n } from "@project/shared";
import ScrollContainer from "react-indiana-drag-scroll";
import { useRouter } from "next/router";
import {
  STARTUP_SUPPORT_MENUS_EN,
  STARTUP_SUPPORT_MENUS_JA,
} from "../../../utils/startupSupportMenus";
import {
  STARTUP_STATUS_EN,
  STARTUP_STATUS_JP,
} from "../../../utils/dummyStatusSupport";

const CardContainer = styled(ScrollContainer)`
  grid-row-gap: 24px;
  display: grid;
  grid-column-gap: 24px;
  grid-template-columns: 1fr 1fr 1fr;

  & h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 21px;
    color: ${theme.textColor};

    @media (max-width: 995px) and (min-width: 896px) {
      font-size: 14px;
    }
  }
  & p {
    font-size: 12px;
    line-height: 22px;
    color: ${theme.strokeGray};
    margin-bottom: 0px;
  }
  cursor: pointer;
  & .ant-card-body {
    padding: 16px 26px 22px 16px;
  }

  @media (max-width: 895px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: 768px) {
    display: flex;
    & p {
      color: #555555;
    }
  }
`;

const StyledCard = styled(Card)<{ onClick?: any }>`
  border-radius: 10px;
  background: ${theme.white};

  &:hover {
    box-shadow: 4px 4px 20px rgba(237, 71, 71, 0.08);
    & path,
    circle {
      fill: ${theme.buttonRed};
    }
    h2 {
      color: ${theme.buttonRed};
    }
  }

  @media (max-width: 768px) {
    min-width: 314px;
  }
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;

  & svg:first-child {
    width: 33px;
    height: 33px;
  }
`;
const StyledTitle = styled.h2`
  margin-bottom: 32px;
  font-size: 24px;
  line-height: 40px;
  font-weight: 700;
  @media (max-width: 768px) {
    margin-bottom: 24px;
  }
`;
const Wrapper = styled.div`
  background: ${theme.grayBackground};
  padding-top: 46px;
  padding-bottom: 40px;
  @media (max-width: 768px) {
    padding-top: 32px;
    padding-bottom: 32px;
  }
`;
const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 32px;

  & button {
    padding: 0px 16px;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

const Container = styled.div`
  max-width: 1440px;
  margin: auto;
  @media (max-width: 1550px) {
    padding: 0px 5% 0px 5%;
  }
  @media (max-width: 1440px) {
    padding: 0px 9.02% 0px 8.33%;
  }
  @media (max-width: 1245px) {
    padding: 0px 5% 0px 5%;
  }
  @media (max-width: 1150px) {
    padding: 0px 3% 0px 3%;
  }
`;

interface Props {
  city?: string;
}
export const SupportCard = ({ city }: Props) => {
  const { t } = useTranslation();
  const router = useRouter();
  const [menu, setMenu] = useState([]);
  const [contents, setContents] = useState(null);

  const goToPage = () => {
    if (city) {
      localStorage.setItem("support-key-city", city);
    }
    router.push("/startup-support");
  };
  const handleClick = (key: string) => {
    localStorage.setItem("support-key", key);
    goToPage();
  };

  const getFilterData = (allMenus, allContents) => {
    const citiesContents = {};
    const citiesMenu = [];
    let count = 0;
    allMenus.map((menuItem, index) => {
      const filteredContents = allContents[index].filter(
        (content) => content?.city === city
      );
      if (filteredContents.length !== 0) {
        citiesMenu.push(menuItem);
        citiesContents[count] = filteredContents;
        count += 1;
      }
    });
    setMenu(citiesMenu);
    setContents(citiesContents);
  };

  useEffect(() => {
    if (i18n.language.startsWith("ja")) {
      if (city) {
        getFilterData(STARTUP_SUPPORT_MENUS_JA, STARTUP_STATUS_JP);
      } else {
        setMenu(STARTUP_SUPPORT_MENUS_JA);
        setContents(STARTUP_STATUS_JP);
      }
    } else {
      if (city) {
        getFilterData(STARTUP_SUPPORT_MENUS_EN, STARTUP_STATUS_EN);
      } else {
        setMenu(STARTUP_SUPPORT_MENUS_EN);
        setContents(STARTUP_STATUS_EN);
      }
    }
  }, [i18n.language]);
  if (city && menu.length === 0) {
    return <></>;
  }
  return (
    <>
      <Wrapper>
        <Container>
          <StyledTitle>{t("Startup support")}</StyledTitle>
          <CardContainer>
            {menu &&
              menu.map((item, index) => {
                if (index < 6) {
                  return (
                    <StyledCard
                      onClick={() => handleClick(item.key)}
                      key={index}
                    >
                      <IconWrapper>
                        {item.icon}
                        <CardArrow />
                      </IconWrapper>
                      <h2>
                        {item.label} ({contents[index]?.length})
                      </h2>
                    </StyledCard>
                  );
                }
              })}
          </CardContainer>
          {menu && menu.length > 6 && (
            <ButtonContainer>
              <Button
                padding="0px 12px"
                minheight={48}
                minwidth={136}
                onClick={goToPage}
                type="primary"
              >
                {t("Show Other Supports")}
              </Button>
            </ButtonContainer>
          )}
        </Container>
      </Wrapper>
    </>
  );
};
