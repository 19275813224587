import React from "react";
import { styled } from "linaria/react";
import { useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import { Button } from "@project/shared";
import { theme } from "@project/shared/src/theme";
import { CalenderCard } from "../../molecules";
import { GetTopEventsList } from "../../../service/event";
import { notification } from "antd";
import { useRouter } from "next/router";

const Container = styled.section`
  display: flex;
  overflow-x: auto;
  margin-top: 30px;
  padding-bottom: 15px;
  & > div:last-child {
    margin-right: 0px;
  }
  ::-webkit-scrollbar {
    height: 6px;
    border-radius: 14px;
    cursor: pointer;
  }

  ::-webkit-scrollbar-thumb {
    background: #d1cece;
    border-radius: 14px;
    cursor: pointer;
  }
  ::-webkit-scrollbar-thumb:hover {
    background: #999393;
    outline: 1px solid slategrey;
    cursor: pointer;
  }

  @media (max-width: 768px) {
    margin-top: 24px;
  }
`;
const TopWrapper = styled.div`
  margin: 0 auto;
  width: 100%;
  display: flex;
  justify-content: space-between;
  & h2 {
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 40px;
    margin-bottom: 0px;
  }
`;

const Wrapper = styled.div`
  padding-top: 40px;
  padding-bottom: 40px;
  background: ${theme.grayBackground};
  & .button-sm {
    display: none;
  }
  & button {
    min-height: 48px;
    min-width: 157px;
  }

  & > div {
    max-width: 1440px;
    margin: 0 auto;
    @media (max-width: 1550px) {
      padding: 0px 5% 0px 5%;
    }
    @media (max-width: 1440px) {
      padding: 0px 9.02% 0px 8.33%;
    }
    @media (max-width: 1245px) {
      padding: 0px 5% 0px 5%;
    }
    @media (max-width: 1150px) {
      padding: 0px 3% 0px 3%;
    }
    @media (max-width: 768px) {
      padding: 0px 16px;
      & .button-sm {
        display: block;
      }

      & .button-lg {
        display: none;
      }

      & button {
        margin: auto;
        margin-top: 24px;
      }
    }
  }

  @media (max-width: 768px) {
    padding-top: 32px;
    padding-bottom: 32px;
  }
`;

interface CalendarProps {
  city?: any;
  initialData: any;
}

export const CalenderList = ({ city, initialData }: CalendarProps) => {
  const { t } = useTranslation();
  const router = useRouter();

  const { data } = useQuery<any>(["get-top-events", city], GetTopEventsList, {
    refetchOnWindowFocus: false,
    initialData: initialData,
    onError: () => {
      notification.error({
        message: t("Error occured"),
      });
    },
  });

  const goToPage = () => {
    if (city) {
      localStorage.setItem("events-city", city);
    }
    router.push("/events");
  };
  const ViewCalendarButton = (className: string) => (
    <Button
      padding="12px 23px"
      minwidth={154}
      minheight={46}
      type="primary"
      className={className}
      onClick={goToPage}
    >
      {t("View calendar")}
    </Button>
  );

  if (data?.data.length === 0) {
    return <></>;
  }
  return (
    <>
      <Wrapper>
        {data?.data?.length > 0 && (
          <div>
            <TopWrapper>
              <h2> {t("Top Events")}</h2>
              {ViewCalendarButton("button-lg")}
            </TopWrapper>
            <Container>
              {data &&
                data?.data?.map((item) => (
                  <CalenderCard
                    url={item?.url}
                    key={item.id}
                    image={item.image}
                    title={item.title}
                    startDate={item.startDate}
                    endDate={item.endDate}
                  />
                ))}
            </Container>
            {ViewCalendarButton("button-sm")}
          </div>
        )}
      </Wrapper>
    </>
  );
};
