import React, { Dispatch, SetStateAction } from "react";

import { styled } from "linaria/react";
import { Menu } from "antd";
import { useQuery } from "react-query";
import { FetchTag } from "../../../service/article";
interface Props {
  selectedMenu: any;
  setSelectedMenu: Dispatch<SetStateAction<any>>;
  city?: any;
}

const MainContainer = styled.div`
  position: relative;
  width: 303px;
  & .side-menu {
    width: 200px;
  }
  @media (min-width: 1441px) {
    min-width: 300px;
  }
  @media (max-width: 768px) {
    display: none;
  }
`;

export const Sider: React.FC<Props> = ({
  selectedMenu,
  setSelectedMenu,
  city,
}: any) => {
  const handleClick = ({ key }) => {
    setSelectedMenu(key);
    window.localStorage.setItem("articalTab", key);
  };
  const { data: tags } = useQuery<any, any>(["fetchTag", city], FetchTag, {
    refetchOnWindowFocus: false,
  });

  return (
    <MainContainer>
      <Menu
        mode="inline"
        className="side-menu"
        selectedKeys={[selectedMenu]}
        onClick={handleClick}
      >
        {tags &&
          tags?.map((item) => (
            <Menu.Item key={item?.id}>{item?.name}</Menu.Item>
          ))}
      </Menu>
    </MainContainer>
  );
};
