import { API, i18n } from "@project/shared";

export const GetLinkList = ({ pageParam, queryKey }) => {
  const city = queryKey[1];
  return API.get("/users/links", {
    params: {
      language: i18n.language?.startsWith("en") ? "en" : "ja",
      page: pageParam || 1,
      pageSize: 3,
      city: city,
    },
  });
};
