import React from "react";
import { useQuery } from "react-query";
import { styled } from "linaria/react";
import { CityList, GetCityInformation } from "../../../service/city";
import { theme } from "@project/shared/src/theme";
import { useTranslation } from "react-i18next";
import { CityCard } from "../CityCard";
import { notification } from "antd";

const Wrapper = styled.div`
  max-width: 1440px;
  margin: auto;
  margin-bottom: 100px;

  @media (min-width: 1550px) {
    max-width: 1460px;
  }

  @media (max-width: 1550px) {
    padding: 0px calc(5% - 10px);
  }
  @media (max-width: 1440px) {
    padding: 0px calc(9.02% - 10px) 0px calc(8.33% - 10px);
  }
  @media (max-width: 1245px) {
    padding: 0px calc(5% - 10px);
  }
  @media (max-width: 1150px) {
    padding: 0px calc(3% - 10px);
  }
  @media (max-width: 768px) {
    padding: 0px 6px;
  }
`;

const CityWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`;

const StyledDiv = styled.div`
  & .title {
    font-weight: bold;
    font-size: 24px;
    line-height: 40px;
    color: ${theme.textColor};
    margin-bottom: 20px;
    margin-top: 20px;
    padding: 0px 10px;
  }
`;

interface Props {
  initialCityLists: any;
  initialCityInfos: any;
}
const BrowserCity = ({ initialCityLists, initialCityInfos }: Props) => {
  const { t } = useTranslation();

  const { data } = useQuery<any, any>("getCityList", CityList, {
    initialData: initialCityLists,
    refetchOnWindowFocus: false,
  });

  const { data: cities } = useQuery<any>(
    ["get-city-information"],
    GetCityInformation,
    {
      initialData: initialCityInfos,
      refetchOnWindowFocus: false,
      onError: () => {
        notification.error({
          message: t("Error occured"),
        });
      },
    }
  );

  const getInformation = (city: number) => {
    const cityInfo = cities?.data?.find((item: any) => {
      return item.id === city;
    });
    return cityInfo;
  };

  return (
    <>
      <Wrapper>
        <StyledDiv>
          <h2 className={"title"}>{t("Browse by cities")}</h2>
          <CityWrapper>
            {data &&
              data?.map((item, index) => {
                const city = getInformation(item.id);
                return (
                  <CityCard
                    key={`city-${index}`}
                    slug={item.slug}
                    population={item.population}
                    support={item.support}
                    university={item.university}
                    placeName={item.placeName}
                    description={item.description}
                    image={item.image}
                    count={index + 1}
                    news={city?.news}
                    event={city?.events}
                    article={city?.articles}
                  />
                );
              })}
          </CityWrapper>
        </StyledDiv>
      </Wrapper>
    </>
  );
};

export { BrowserCity };
